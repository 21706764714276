<template>
  <div class="all">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('invite[0]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="activity">
      <div style="margin: 0 auto;text-align: center;line-height: 25px;font-size: 18px">
        <div class="xxxx" v-if="!show_idcode">{{$t("invite[4]")}}</div>
        <div v-show="show_idcode">
          <div class="title_code" style="color: #fff">{{$t("invite[1]")}}</div>
          <div class="text_code" style="margin: 10px">{{idcode}}</div>

          <div class="QRCode" id="qrcode"/>
          <div class="text_desc">{{$t("invite[2]")}}</div>
      </div>
      </div>
    </div>
    <van-button v-show="show_idcode" @click="copyCode">{{ $t('invite[3]') }}</van-button>
    <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
  </div>
</template>
<script>
  import QRCode from "qrcodejs2";
  import { Toast, Dialog } from "vant";
  import config from "@/config";

var c;
export default {
  data() {
    return {
      //show  shows
      chenggong: false, //  领取成功时的弹窗
      shibai: false, // 不能领取时的弹窗
      inviteInfo: {
        sure_receive_money: "",
        item: {
          cover_img: "",
        },
      }, //保存邀请数据

      flag: true, //判断是否领取过
      vip_level: "", //vip等级
      invalid_receive_money: 0,
      award_model: 0, //模式
      agent_type:0,
      show_idcode:true,
      showAddress:true,
      inviteUrl:'',
      idcode:null,
      qrcode:null,
    };
  },
  props: ["id", "sign_id"],
  created() {
    this.inviteUrl = config.ApiUrl+"/#/register/"+this.UserInfo.idcode;
    this.idcode = this.UserInfo.idcode;
    let level = this.UserInfo.vip_level;
    if (level>=4){
      this.show_idcode = true;
    }
  },
  mounted() {
    var that = this;
    this.$Model.GetUserInfo(data=>{
      if (data.code==1){
        that.agent_type = data.info['agent_type'];
        if (that.agent_type ==1){
          that.show_idcode = true;
        }
        that.doShow();
      }
    })

  },
  computed: {
    //领取按钮
    receive() {
      if (this.inviteInfo.sure_receive_money == 0) {
        return "sem quantia"; //余额不足
      }
      if (this.inviteInfo.sure_receive_money != 0) {
        return "Receber"; //领取
      } else if (this.flag === false) {
        return "Já recebido hoje"; //今日已领取
      }
    },
    //活动规则
    explain() {
      if (this.inviteInfo) {
        return this.inviteInfo.item.explain;
      } else {
        return "<p></p>";
      }
    },
  },
  filters: {
    只显示小数后两位: function (n) {
      return n.substr(0, n.indexOf(".") + 3);
    },
  },
  methods: {
    //复制码和金额
    copyCode() {
      var copyBox = document.getElementById("inputBox");
      copyBox.value = this.idcode;
      copyBox.select();
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$Dialog.Toast(this.$t('recharge.label[5]'));
    },
    toWallet() {
      this.$router.push("/user/wallet");
    },
    toPromote() {
      this.$router.push("/user/promote");
    },
    doShow(){
      if (!this.qrcode){
        var codeElement = document.getElementById("qrcode");
        this.qrcode = new QRCode(codeElement, {
          text: this.inviteUrl,
          width: 140,
          height: 140,
          colorDark : "#000000",
          colorLight : "#ffffff",
          correctLevel : QRCode.CorrectLevel.H
        });
        let b = 100;
        // new QRCode(codeElement, this.InitData.setting.service_url);
      }
    },
  },
};
</script>
<style scoped>
  .all {
    min-height: 100vh;
    background: url(~@/assets/img/new/invite_bg.png) no-repeat;
    background-size:  100% auto;
    background-color: #4F73FD;
  }

  .activity {
    width: calc(100% - 40px);
    height: 350px;
    position: absolute;
    margin-top: 350px;
    margin-left: 20px;
    color:var(--font_subtitle);
    border-radius: 10px;
    background-image: url(~@/assets/img/new/invite_bg1.png);
    background-repeat: no-repeat;
    background-size:  100% 100%;
  }
  .title_code{
    height: 46px;
    line-height: 46px;
    color: #fff;
  }
  .text_code{
    color: #005FFB;
    font-size: 28px;
    font-weight: 600;
  }

  .text_desc {
    margin: 10px;
  }

  .cover_img {
    width: 100%;
    height: auto;
  }
  .cover_img > img {
    width: 100%;
    height: auto;
    display: block;
  }
  .inviteTit {
    width: 220px;
    height: 125px;
    /* background:pink; */
    position: absolute;
    top: 130px;
    left: 80px;
    padding: 10px 30px;
    text-align: center;
    font-weight: 600;
  }
  .inviteTit > p:first-child {
    color: #fff;
    font-size: 18px;
    line-height: 20px;
  }
  .inviteTit > p:last-child {
    color: rgb(0, 255, 255);
    font-size: 25px;
    line-height: 30px;
  }

  .QRCode {
    margin: 15px auto;
    height: 140px;
    width: 140px;
    text-align: center;
  }
  .xxxx{
    margin: 140px 0;
  }

  .successImg > img {
    width: 100%;
    height: 100%;
  }

</style>
<!-- <style scoped>
.all {
	overflow: auto;
	height: 100%;
}

.invite {
	width: 100%;
	font-size: 16px;
	background-image: url('../../../public/static/images/invite_bg.png');
	background-repeat: no-repeat;
	background-size: 100%;
}

.title {
	color: white;
	height: 46px;
	line-height: 46px;
	font-size: 17px;
	letter-spacing: 1px;
	position: fixed;
	background: #6b41c1;
	z-index: 99;
	top: 0;
	width: 100%;
	text-align: center;
}

.thing {
	padding-top: 370px;
}

.subtitle div {
	margin: 0px auto 20px auto;
	text-align: center;
	line-height: 25px;
	color: #4e51bf;
	letter-spacing: 1;
}

.subbtn {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn {
	background-color: #ffdd3c;
	width: 200px;
	height: 45px;
	border-radius: 5px;
	text-align: center;
	line-height: 45px !important;
	color: #ff395b !important;
	font-size: 25px;
	margin-top: -110px !important;
	position: absolute;
	left: 53%;
	transform: translate(-51%, 0);
	font-family: Fette Egyptienne;
}

.mybody {
	width: 100%;
	margin-top: -280px;
}

.head {
	display: flex;
	justify-content: space-around;
	margin-top: -20px;
	font-size: 16px;
}

.head_one {
	margin-top: 30px;
	height: 170px;
}

.head_one_btn {
	text-align: center;
	padding: 10px 0;
}

.head_one_box {
	width: 150px;
	color: #fff;
	background-image: linear-gradient(to right, #fb61cc, #ab03fe);
	border-radius: 20px;
	margin-left: 8px;
}

.head_two_box {
	width: 100%;
	text-align: center;
	border-radius: 20px;
}

.head_one_btn2 {
	margin: 15px 25px 0 25px;
	width: 115px;
	height: 135px;
	text-align: center;
	line-height: 110px;
	background: url('../../../public/static/images/80.png') no-repeat;
	background-size: 100%;
	border-radius: 10px;
	font-size: 45px;
	color: #fff;
	font-weight: 600;
}

.head_one_btn3 {
	color: #fff;
	border: 1px solid #fff;
	width: 70px;
	text-align: center;
	font-size: 12px;
	border-radius: 5px;
	margin-top: -56px;
	margin-left: 48px;
}

.head_two {
	margin-top: 30px;
}

.head_two_btn {
	width: 150px;
	background-color: #eff0f2;
	text-align: center;
	border-radius: 20px;
	padding: 10px 0;
	margin-left: 10px;
	color: #fff;
	background-image: linear-gradient(to right, #fb61cc, #ab03fe) !important;
}

.head_two_btn1 {
	margin: 15px 15px 0 25px;
	width: 115px;
	height: 135px;
	text-align: center;
	background: url('../../../public/static/images/60.png') no-repeat;
	background-size: 100%;
	border-radius: 10px;
	padding-top: 3px;
}

.head_two_btn2 {
	width: 50px;
	height: 50px;
	color: #fff;
	border-radius: 50%;
	text-align: center;
	line-height: 80px;
	margin: 10px 0 0 32px;
	font-weight: 600;
}

.head_two_btn3 {
	font-size: 12px;
	color: #fff;
	border: 1px solid #fff;
	width: 88px;
	height: 18px;
	border-radius: 5px;
	padding: 1px;
	margin-top: -58px;
	margin-left: 38px;
	text-align: center;
}

.neck {
	width: 100%;
	display: flex;
	justify-content: center;
}

.neck_one {
	width: 180px;
	height: 40px;
	color: #fff;
	background-color: #9f00ff;
	text-align: center;
	line-height: 40px;
	border-radius: 13px;
	font-size: 15px;
	letter-spacing: 1px;
	margin-top: 60px;
	margin-bottom: 20px;
}

.chest {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
	font-size: 14px;
}

.chest_one {
	background: #eff0f2;
	width: 100px;
	border-radius: 15px;
	text-align: center;
	padding-top: 10px;
}

.chest_one p {
	margin: auto;
	margin-top: 20px;
	padding-bottom: 20px;
	width: 90px;
}

.chest_two {
	background: #eff0f2;
	width: 100px;
	border-radius: 15px;
	text-align: center;
	padding-top: 10px;
}

.chest_two p {
	margin: auto;
	margin-top: 20px;
	width: 90px;
}

.chest_three {
	background: #eff0f2;
	width: 100px;
	border-radius: 15px;
	text-align: center;
	padding-top: 10px;
}

.chest_three p {
	width: 80px;
	margin: auto;
	text-align: center;
}

.chest_three p:nth-of-type(1) {
	width: 90px;
	margin-top: 20px;
}

.chest_three p:nth-of-type(3) {
	padding-bottom: 20px;
}

.abdomen {
	margin-top: 20px;
}

.abdomen_head {
	width: 180px;
	padding: 0px 0;
	border-radius: 15px;
	text-align: center;
	line-height: 40px;
	color: #fff;
	background: #9f00ff;
	font-size: 15px;
	letter-spacing: 1px;
	margin-top: 20px;
}

.abdomen_one {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.abdomen_one p {
	margin: 10px 20px 10px 20px;
	color: #dd1d61;
	font-size: 13px;
}

.abdomen_two {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
	font-size: 13px;
}

.abdomen_neck {
	width: 100px;
	border-radius: 15px;
	background: #eff0f2;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
}

.abdomen_neck p:nth-of-type(2) {
	width: 100px;
	margin-left: 0;
}

.abdomen_neck p {
	width: 80px;
	margin-left: 10px;
	font-size: 13px;
}

.leg {
	margin-top: 40px;
}

.abdomen_leg {
	width: 100px;
	border-radius: 15px;
	background: #eff0f2;
	padding-top: 20px;
	padding-bottom: 10px;
}

.abdomen_leg p {
	width: 80px;
	margin-left: 10px;
	text-align: center;
}

.abdomen_leg p:nth-of-type(2) {
	width: 100px;
	margin-left: 0;
}

.abdomen_foot {
	width: 100px;
	border-radius: 15px;
	background: #eff0f2;
	padding-top: 20px;
}

.abdomen_foot p {
	width: 70px;
	margin-left: 20px;
	margin-top: 12px;
}

.leg_one {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}

.subhead {
	height: 40px;
	width: 180px;
	padding: 0 10px;
	color: #fff;
	background-color: #9f00ff;
	border-radius: 15px;
	text-align: center;
	line-height: 40px;
	margin-bottom: 8px;
}

.leg_two {
	display: flex;
	justify-content: center;
}

table {
	border-collapse: collapse;
	margin: 10px;
	font-size: 13px;
}

table tr td {
	border: 2px solid white;
	min-width: 120px;
	height: 35px;
	text-align: center;
	letter-spacing: 0.8px;
	border: none;
}

table tr td:nth-of-type(2) {
	border-right: 2px #fff solid;
	border-left: 2px #fff solid;
}

table tr:nth-child(odd) {
	background: #eff0f2;
}

table tr:nth-of-type(1) {
	background-color: #9f00ff;
	font-size: 13px;
	color: black;
}

table tr td:nth-of-type(3) {
	font-size: 12px;
	font-family: Fette Egyptienne;
}

table tr:nth-of-type(1) td {
	color: #fff !important;
}

table tr td:nth-of-type(3) {
	color: #797bcd;
}

table tr:nth-of-type(1) td {
	color: black;
}

.foot {
	margin-top: 20px;
}

.foot_one {
	display: flex;
	justify-content: center;
}

.foot_sunhead {
	width: 180px;
	height: 40px;
	color: #fff;
	background-color: #9f00ff;
	border-radius: 15px;
	text-align: center;
	line-height: 40px;
	font-size: 16px;
	margin-top: 20px;
	margin-bottom: 10px;
}

.foot_two {
	display: flex;
	justify-content: center;
}

.foot_content {
	width: 320vh;
	margin: 10px 30px 0 30px;
	font-size: 13px;
	margin-bottom: 50px;
	font-weight: 600;
}

.foot_content p {
	line-height: 25px;
}

/* 弹窗样式 */
.cash {
	background-color: white;
}

.cash_one {
	display: flex;
	flex-direction: row;
}

.cash_sign {
	width: 120px;
	height: 120px;
	border-radius: 10px;
	margin: 10px 0 10px 10px;
	background: url('../../../public/static/images/photo.png') no-repeat;
	background-size: 100%;
}

.cash_sign div {
	color: #a432ec;
	margin-top: 35px;
	font-size: 15px;
	text-align: center;
	font-weight: 600;
}

.cash_head {
	margin-top: 20px;
	font-size: 13px;
	font-weight: 600;
}

.cash_head h3 {
	margin-bottom: 10px;
	font-size: 15px;
	font-weight: 600;
}

.cash_head p:nth-of-type(1) {
	margin-bottom: 10px;
}

.cash_head span {
	color: #4e51bf;
}

.cash_two {
	height: 240px;
	margin-top: -15px;
}

.cash_two h2 {
	text-align: center;
	color: #fe889d;
	margin: 0px 0 10px 0;
	letter-spacing: 1px;
	font-size: 20px;
}

.cash_word {
	margin: 0 20px 0 20px;
	background-color: #eff0f2;
	padding: 15px 25px;
	line-height: 23px;
	font-size: 13px;
	font-weight: 600;
}

.cash_btn {
	display: flex;
	justify-content: space-around;
	margin-bottom: 30px;
}

.cash_btn1 {
	width: 130px;
	height: 40px;
	margin-top: 20px;
	background-color: #4e51bf;
	color: white;
	text-align: center;
	line-height: 40px;
	border-radius: 13px;
}

.cash_btn2 {
	width: 130px;
	height: 40px;
	margin-top: 20px;
	background-color: #4e51bf;
	color: white;
	text-align: center;
	line-height: 40px;
	border-radius: 13px;
}

.invite >>> .van-dialog {
	overflow: initial !important;
	padding: 8px;
	background: #4e51bf;
	border-radius: 6px;
	background-image: linear-gradient(to bottom, #ff92a4, #5153be);
}

.invite >>> .van-dialog .van-dialog__content {
	overflow: hidden;
}

.van-dialog >>> .van-dialog__footer {
	display: none;
}

.deleteimg {
	position: absolute;
	height: 40px;
	top: 440px;
	left: 140px;
}

.fu {
	width: 100%;
	text-align: center;
}

.fu88 {
	font-size: 50px;
	margin-top: 15px !important;
	margin-left: auto;
	color: #ee498b !important;
}

.one_img {
	text-align: center;
	color: #4e51bf;
	min-height: 225px;
	font-size: 16px;
	font-weight: 600;
	padding-top: 20px;
	padding-left: 20px;
}

.subtitle {
	position: relative;
	top: -300px;
	background: url('../../../public/static/images/img1.png') no-repeat;
	background-size: 300px;
	background-position: center;
}

.give {
	position: absolute;
	top: 130px;
	left: 53%;
	transform: translate(-50%, 0);
	color: #fff !important;
	font-size: 12px;
	background: #ee498b;
	width: 100px;
	border-radius: 5px;
	height: 18px;
	line-height: 18px !important;
}

.rmb {
	color: #fff;
}
</style>
 -->
